#btn1 {
  position: absolute;
  top: 0;
  z-index: 999999;
  width: 100px;
  height: 30px;
  opacity: 0;
}

#btn2 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999999;
  width: 100px;
  height: 30px;
  opacity: 0;
}

#btn3 {
  position: absolute;
  top: 70px;
  right: 0;
  z-index: 999999;
  width: 100px;
  height: 30px;
  opacity: 0;
}

@media screen and (min-aspect-ratio: 1280/800) {

  .print__button {
    width: 500px!important;
    height: 110px!important;
    font-size: 28px!important;
  }

  .ticket {
    width: 400px!important;
    height: 400px!important;
  }
}