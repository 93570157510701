.label {
  margin-bottom: 10px;
}


.input {
  border: 1px solid rgba(0, 0, 0, .1);
  transition: border 0.1s ease-out;
  text-align: left;
  padding: 10px 15px;
  font-size: inherit;
  &:focus {
    outline: none;
  }
    &_box {
    }
}

.check {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 15px;
    -webkit-transition: .4s;
    transition: .4s;
    &:before{
        position: absolute;
        content: "";
        border-radius: 50%;
        height: 18px;
        width: 18px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    &_box {
        position: relative;
        display: inline-block;
        margin-left: 10px;
        width: 40px;
        height: 26px;
        > input{
            opacity: 0;
            width: 0;
            height: 0;
        }
    }
}

.multiselect {
  width: 100%;
  height: 100%;
  min-width: 200px;

  > div {
    height: 100% !important;
  }
}

.select {
  @extend .multiselect;
  > div {
    align-items: center;
  }
}